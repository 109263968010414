import React, { memo } from "react";
import { NodeProps, XYPosition } from "@xyflow/react";
import { OBJECT_TYPE } from "@zixi/models";

export const VISUALIZATION_NODE_TYPE = "visualizationNode";
export type VisualizationObject = {
    id: string;
    position: XYPosition;
    data: any;
    type: OBJECT_TYPE.GRID | OBJECT_TYPE.GRID_GROUP;
    // id: number;
    name: string;
    status: string; // "good" | "bad" | "pending" | "disabled";
    detailsRoute: string;
};

export const VisualizationNode = memo(function VisualizationNode({ data: visualizationObject }: any) {
    return (
        <div className={`visualization-node status-${visualizationObject.status}`}>
            <span>{visualizationObject.name}</span>{" "}
            <a href={visualizationObject.detailsRoute} title="Open Grid" target="_blank">
                <i className="fa fa-external-link-alt fa-xs"></i>
            </a>
        </div>
    );
});
