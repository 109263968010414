<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" *ngIf="!loading">
    <div zmid="title" class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">
                        {{ "CREATE_NEW_GRID_GROUP" | translate
                        }}<fa-icon
                            class="ms-2 tourStartButton"
                            icon="question-circle"
                            #tooltip="matTooltip"
                            matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_CREATING_A_GRID_GROUP_BY_CLICKING_HERE' | translate }}"
                            matTooltipPosition="below"
                            matTooltipClass="below"
                            size="xs"
                            (click)="startTour()"
                        ></fa-icon>
                    </h1>
                    <h1 *ngIf="isClone">{{ "CLONE_GRID_GROUP" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_GRID_GROUP" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area pt-0" scrollSpy [spiedTags]="['SECTION']">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS" id="scrollParent">
                    <section id="info">
                        <div class="form-section-title">
                            <h3
                                class="section-title mt-3"
                                title="{{ 'INFO' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && infoHasErrors(form) }"
                            >
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <div class="form-group">
                            <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }">
                                {{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                            </label>
                            <input
                                zmid="name-input"
                                type="text"
                                id="name"
                                name="name"
                                placeholder="{{ 'NAME' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                [(ngModel)]="gridGroup.name"
                                required
                                minlength="{{ minLength }}"
                                pattern="{{ constants.validators.name }}"
                                #name="ngModel"
                            />
                            <div *ngIf="name.invalid" class="invalid-feedback">
                                <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="name.errors.minlength">
                                    {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                                </div>
                                <div *ngIf="name.errors.pattern">
                                    {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                                </div>
                            </div>
                        </div>
                        <!-- Public -->
                        <div class="form-group" tourAnchor="firstGridGroupFormAnchor">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="public"
                                    name="public"
                                    [disabled]="cantChangePublic$ | async"
                                    [(ngModel)]="gridGroup.public"
                                />
                                <label class="form-check-label" for="public"
                                    >{{ "PUBLIC" | translate }}
                                    <small
                                        ><em>({{ "AVAILABLE_TO_NONE_ADMIN" | translate }})</em></small
                                    ><fa-icon icon="info-circle" [ngbTooltip]="PublicContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #PublicContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.GRID_GROUP_PUBLIC" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                            </div>
                        </div>
                    </section>
                    <section id="filter" tourAnchor="secondGridGroupFormAnchor">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'FILTER' | translate }}">
                                {{ "FILTER" | translate }}
                            </h3>
                        </div>
                        <!-- Your Grids-->
                        <div class="form-group">
                            <label for="privateGrids">{{ "YOUR" | translate }} {{ "GRIDS" | translate }}</label>
                            <zx-grid-select name="privateGrids" [(model)]="privateGrids" [disabled]="gridGroup.public" filter="private"></zx-grid-select>
                            <small *ngIf="gridGroup.public">
                                {{ "CANNOT_CONFIGURE_PUBLIC_GRID_GROUP_WITH_PRIVATE_GRIDS" | translate }}
                            </small>
                        </div>
                        <!-- Public Grids-->
                        <div class="form-group">
                            <label for="publicGrids">{{ "PUBLIC" | translate }} {{ "GRIDS" | translate }}</label>
                            <zx-grid-select name="publicGrids" [(model)]="publicGrids" filter="public"></zx-grid-select>
                        </div>
                    </section>
                    <section id="display" tourAnchor="thirdGridGroupFormAnchor">
                        <div class="form-section-title">
                            <h3
                                class="section-title"
                                title="{{ 'DISPLAY' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && displayHasErrors(form) }"
                            >
                                {{ "DISPLAY" | translate }}
                            </h3>
                        </div>
                        <!-- Mode -->
                        <fieldset class="form-group">
                            <legend>{{ "MODE" | translate }}</legend>
                            <div class="form-check">
                                <input type="radio" id="mode_stack" name="mode_stack" class="form-check-input" value="stack" [(ngModel)]="gridGroup.mode" />
                                <label class="form-check-label" for="mode_stack">{{ "CUSTOM_LAYOUT" | translate }}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" id="mode_cycle" name="mode_cycle" class="form-check-input" value="cycle" [(ngModel)]="gridGroup.mode" />
                                <label class="form-check-label" for="mode_cycle">{{ "CYCLE_THROUGH_GRIDS_ONE_BY_ONE" | translate }}</label>
                            </div>
                        </fieldset>
                        <!-- Cycle Pages Automatically -->
                        <div class="form-group">
                            <label
                                >{{ "CYCLE_PAGES_AUTOMATICALLY" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="CycleContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #CycleContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.CYCLE_PAGES_AUTOMATICALLY" | translate }}
                                    </p></ng-template
                                ></label
                            >
                            <div class="input-group align-items-center">
                                <div class="input-group-prepend">
                                    <div class="input-group">
                                        <div class="form-check form-check-inline me-2">
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="cycle_pagination"
                                                name="cycle_pagination"
                                                [(ngModel)]="gridGroup.cycle_pagination"
                                                [disabled]="gridGroup.mode !== 'cycle'"
                                            />
                                            <label class="form-check-label" for="cycle_pagination">{{ "ENABLE" | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group-div">
                                    <input
                                        type="number"
                                        id="cycle_pagination_interval"
                                        name="cycle_pagination_interval"
                                        placeholder="{{ 'INTERVAL' | translate }}"
                                        class="form-control"
                                        [disabled]="!gridGroup.cycle_pagination || gridGroup.mode !== 'cycle'"
                                        required="gridGroup.cycle_pagination && gridGroup.mode == 'cycle'"
                                        [(ngModel)]="gridGroup.cycle_pagination_interval"
                                        #interval="ngModel"
                                        [ngClass]="{ 'is-invalid': form.submitted && interval.errors }"
                                    />
                                    <div *ngIf="interval.invalid" class="invalid-feedback">
                                        <div *ngIf="interval.errors.required">{{ "CYCLE_PAGES_INTERVAL" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    </div>
                                </div>
                                <div class="input-group-append ms-2">
                                    <label class="not-bold mb-0">{{ "SECONDS" | translate | lowercase }}</label>
                                </div>
                            </div>
                        </div>
                    </section>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
