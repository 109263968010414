import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import type { ComponentProps } from "react";
import { GridsService } from "../grids.service";
import { DropContainer as DropContainerReactComponent } from "./drop-container/drop-container";
import { firstValueFrom } from "rxjs";
import { OBJECT_TYPE } from "@zixi/models";
import { Grid } from "../grid";
import { GridGroup } from "../grid-group";
import { VisualizationObject } from "./drop-container/visualization-node";
import { GridVisualizationService } from "./grid-visualization.service";
import { CustomVisualization } from "@zixi/models";
import { ReactFlowJsonObject, Node } from "@xyflow/react";
@Component({
    selector: "app-grid-visualization",
    templateUrl: "./grid-visualization.component.html",
    styleUrls: ["./grid-visualization.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class GridVisualizationComponent implements OnInit {
    dropContainerReactComponent = DropContainerReactComponent;
    dropContainerReactComponentProps: ComponentProps<typeof DropContainerReactComponent> = {
        flowJson: null,
        saveFlowCallback: this.saveFlow.bind(this)
    };
    // visualizationObjects: VisualizationObject[];
    visualizationObjects: any[];
    isLoading = true;
    selectedVisualization: CustomVisualization = null;

    constructor(private gridsService: GridsService, private gridVisualizationService: GridVisualizationService) {}

    async ngOnInit() {
        const [grids, gridsGroup, gridVisualizationList] = await Promise.all([
            firstValueFrom(this.gridsService.getGridsNew()),
            firstValueFrom(this.gridsService.getGridsGroupsNew()),
            this.gridVisualizationService.getGridVisualizationList()
        ]);
        if (gridVisualizationList.length) {
            this.selectedVisualization = gridVisualizationList[0];
            this.dropContainerReactComponentProps.flowJson = JSON.parse(this.selectedVisualization.flow_json);
        }
        this.visualizationObjects = [
            ...(grids || []).map(grid => this.convertToVisualizationObject(OBJECT_TYPE.GRID, grid)),
            ...(gridsGroup || []).map(gridGroup => this.convertToVisualizationObject(OBJECT_TYPE.GRID_GROUP, gridGroup))
        ];
        this.isLoading = false;
    }

    convertToVisualizationObject(type: OBJECT_TYPE.GRID | OBJECT_TYPE.GRID_GROUP, object: Grid | GridGroup) {
        const detailsRoute =
            type === OBJECT_TYPE.GRID
                ? this.gridsService.getGridDetailsRoute(object.id)
                : type === OBJECT_TYPE.GRID_GROUP
                ? this.gridsService.getGridGroupDetailsRoute(object.id)
                : "";
        return {
            type: type,
            id: object.id,
            name: object.name,
            status: "bad", // TODO: get status somehow?
            detailsRoute
        };
    }

    saveFlow(flow: ReactFlowJsonObject<Node<VisualizationObject>, any>) {
        if (this.selectedVisualization) {
            this.gridVisualizationService.updateGridVisualization(this.selectedVisualization.id, {
                flow: flow as any
            });
        } else {
            this.gridVisualizationService.createGridVisualization({
                name: `test ${new Date().getTime()}`,
                flow: flow as any
            });
        }
    }
}
